<template>
    <div class="early">
        <div class="nav">
            <div class="list" :class="active == index ? 'list1' : ''" @click="changeMenu(index)" v-for="(item,index) in navList" :key="index"><div class="name">{{item}}</div></div>
        </div>
      <div class="bot">
          <div class="left">
              <div>
                  <div class="title">已启动前期工作数量统计</div>
                  <div class="echarts" id="echarts1"></div>
                  <div class="title title1">厅管重点项目</div>
                  <div class="echarts" id="echarts2"></div>
              </div>
          </div>
          <div class="middle">
              <div class="mt">
                 <div class="flex" style="justify-content: space-between">
                     <div class="title">前期工作进展统计</div>
                     <div @mouseover="showXl = true" @mouseleave="showXl = false" style="position: relative">
                         <div class="xl">{{searchYear.name}} <i style="color: #fff" class="el-icon-caret-bottom"></i></div>
                         <div class="xlcd" v-if="showXl">
                             <div @click="chooseYear(item)" v-for="(item,index) in roadYear1" :key="index">{{item.name}}</div>
                         </div>
                     </div>
                 </div>
                 <div>
                     <div class="mtt">
                         <div class="echarts1" id="echarts3"></div>
                         <div class="line"></div>
                         <div class="import">
                             <div class="ictzd"><img src="../../assets/images/statistics/ic-tzd.png" alt=""></div>
                             <div class="tzdNum">28<span>个</span></div>
                             <div class="tzdname">厅管重点项目</div>
                         </div>
                     </div>
                     <div class="echarts2" id="echarts4"></div>
                 </div>
              </div>
              <div class="mb">
                  <div class="title">待纳入规划库项目数量</div>
                  <div style="display: flex;align-items: center;justify-content: space-around;margin-top: 20px">
                      <div class="dnr">
                          <div class="tj"><img src="../../assets/images/statistics/ic-tj.png" alt=""></div>
                          <div class="dnrr">
                              <div class="name">高速统计</div>
                              <div class="num">{{dnrgs}}<span>个</span></div>
                              <div class="btn" @click="showModal('高速公路')">明细</div>
                          </div>
                      </div>
                      <div class="dnr">
                          <div class="tj"><img src="../../assets/images/statistics/ic-tj.png" alt=""></div>
                          <div class="dnrr">
                              <div class="name">国道统计</div>
                              <div class="num">{{dnrgd}}<span>个</span></div>
                              <div class="btn"  @click="showModal('普通国道')">明细</div>
                          </div>
                      </div>
                      <div class="dnr">
                          <div class="tj"><img src="../../assets/images/statistics/ic-tj.png" alt=""></div>
                          <div class="dnrr">
                              <div class="name">省道统计</div>
                              <div class="num">{{dnrsd}}<span>个</span></div>
                              <div class="btn"  @click="showModal('普通省道')">明细</div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="right">
              <div class="rt">
                  <div class="title">项目前期工作预警</div>
                  <div class="rtb">
                      <div class="rtbo">
                          <div class="rtboi"><img src="../../assets/images/statistics/ic-dtx.png" alt=""></div>
                          <div class="rtbot">待提醒</div>
                          <div class="rtbon">{{dtx}}</div>
                      </div>
                      <div class="rtbo rtbo1">
                          <div class="rtboi"><img src="../../assets/images/statistics/ic-ddb.png" alt=""></div>
                          <div class="rtbot">待督办</div>
                          <div class="rtbon">{{lqsl}}</div>
                      </div>
                      <div class="rtbo rtbo2">
                          <div class="rtboi"><img src="../../assets/images/statistics/ic-dyt.png" alt=""></div>
                          <div class="rtbot">待约谈</div>
                          <div class="rtbon">{{cqsl}}</div>
                      </div>
                  </div>
              </div>
              <div class="rb">
                  <div class="title">厅管重点项目预警管理</div>
                  <div class="flex" style="justify-content: space-evenly;align-items: flex-start">
                      <div class="flex rbo" @click="changeFX(0)" :class="fxindex === 0 ? '' : 'rbo1' ">
                          <div class="rboi"><img :src="fxindex === 0 ? require('../../assets/images/statistics/ic-yd-active.png') : require('../../assets/images/statistics/ic-yd.png')" alt=""></div>
                          <div  class="flex" style="flex-direction: column">
                              <div class="rbot">黄色预警</div>
                              <div class="rbon">{{lqsl}} <span>个</span></div>
                          </div>
                      </div>
                      <div class="flex rbo" @click="changeFX(1)" :class="fxindex === 0 ? 'rbo1' : '' ">
                          <div class="rboi"><img :src="fxindex === 1 ? require('../../assets/images/statistics/ic-zz-active.png') : require('../../assets/images/statistics/ic-zz.png')" alt=""></div>
                          <div class="flex" style="flex-direction: column">
                              <div class="rbot">红色预警</div>
                              <div class="rbon">{{cqsl}} <span>个</span></div>
                          </div>
                      </div>
                  </div>
                  <div class="tb" style="position: relative">
                      <div class="tablel">
                          <div class="tn">建设单位</div>
                          <div class="dj">项目名称</div>
<!--                          <div class="gm">规模(公里)</div>-->
                      </div>
                      <div style="overflow:scroll;position: relative;margin-left: 20px;height: 23vh">
                          <div ref="lb" :style="{top: tableTop + 'px'}" style="position: absolute">
                              <div v-for="(item,index) in resultData" :key="index" class="flex tc">
                                  <el-tooltip class="item" effect="dark" :content="item.frdw" placement="top-center">
                                          <div class="tn">{{item.frdw}}</div>
                                  </el-tooltip>
                                  <el-tooltip class="item" effect="dark" :content="item.xmmc" placement="top-center">
                                      <div class="dj">{{item.xmmc}}</div>
                                  </el-tooltip>
<!--                                    <div v-for="(subItem,subIndex) in item.cqjd" :key="subIndex">-->
<!--                                        <div class="dj">{{subItem.cqsj}}</div>-->
<!--                                        <div class="gm" >-->
<!--                                            {{subItem.jdmc}}-->
<!--                                        </div>-->
<!--                                    </div>-->

                              </div>
                          </div>
                      </div>


                  </div>
              </div>
          </div>
      </div>
        <div class="modal" v-show="showM">
            <div class="meng">
                <div class="mk">
                    <div class="cw">
                        <img class="close" @click="showM = false" src="../../assets/images/statistics/close.png" alt="">
                        <div class="mt">待纳入规划库高速项目</div>
                       <div style="overflow: hidden">
                           <div class="mc" style="overflow-y: scroll">
                               <div v-for="(item,index) in dnrmxgd" :key="index">{{item.XMMC}}</div>
                           </div>
                       </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "Early",
        data(){
            return{
                piegs:[],
                piegd:[],
                piesd:[],
                pieTotal:0,
                resultData:[],
                searchYear:{name:'2021-2025',Start:'2021',End:'2025'},
                roadYear1:[{name:'2021-2025',Start:'2021',End:'2025'},{name:'2026-2035',Start:'2026',End:'2035'},],
                roadYear:[],
                dtx:0,
                ddb:0,
                dyt:0,
                dnrgs:0,
                dnrgd:0,
                dnrsd:0,
                showXl: false,
                showM: false,
                visibleSize:5,
                lineHeight:43,
                componentTimerInterval:3600000,
                tableTimerInterval:100,
                tableTop:0,
                index:0,
                navList:['公路','水运','铁路','民航','枢纽场站'],
                active:0,
                ydfxData:[],
                zjccData:[],
                fxindex:0,
                dnrmxgd:[],
                warningData:[],
                activeNames:[],
               cqsl:0,
               lqsl: 0,
            }
        },
        mounted(){
            var that = this
            this.getData()
            // this.getFxdata()
            this.getCurrent()
            this.GetEarlyWarningInfo()
        },
        methods: {
            GetEarlyWarningInfo(){
                var that = this
                this.http.post('/api/Plan_high_national_early_node/GetEarlyWarningInfo',{page:1,rows:1000,}).then(res=>{
                    this.warningData = res.data
                    var a = 0
                    var b = 0
                    res.data.map((item,index)=>{
                        item.cqjd.map((subitem,subIndex)=>{
                            if (subitem.cqsj == 0){
                                a++
                                this.ydfxData.push(item)
                            }else{
                                b++
                                this.zjccData.push(item)
                            }
                        })
                        this.activeNames.push(index)
                    })
                    this.ydfxData =  new Set(this.ydfxData);
                    this.resultData = this.ydfxData
                    this.zjccData =  new Set(this.zjccData);
                    this.cqsl = b
                    this.lqsl = a
                })
            },
            getData(){
                var that = this
                var searchData = {
                    wheres:JSON.stringify([{  Name:'Start', Value:this.searchYear.Start},{  Name:'End', Value:this.searchYear.End}]),
                };
                this.http.post('/api/Plan_high_national_early/EarlyStatisticsSummary',searchData).then(res=>{
                    console.log(res)
                    var year = []
                    var bridgeYear = []
                    var tlYear = []
                    var mhYear = []
                    var syYear = []
                    var ysYear = []
                    this.dtx = res['项目前期工作预警'][0].DTX
                    this.ddb = res['项目前期工作预警'][0].DDB
                    this.dyt = res['项目前期工作预警'][0].DYT
                    this.dnrgs = res['待纳入规划库项目统计'].find(item=>item.QQGLLX == '高速公路') == undefined ? 0 :res['待纳入规划库项目统计'].find(item=>item.QQGLLX == '高速公路').Total
                    this.dnrgd = res['待纳入规划库项目统计'].find(item=>item.QQGLLX == '普通国道') == undefined ? 0 :res['待纳入规划库项目统计'].find(item=>item.QQGLLX == '普通国道').Total
                    this.dnrsd = res['待纳入规划库项目统计'].find(item=>item.QQGLLX == '普通省道') == undefined ? 0 :res['待纳入规划库项目统计'].find(item=>item.QQGLLX == '普通省道').Total
                    // this.dnrgd = res['待纳入规划库项目统计'][1]
                    // this.dnrsd = res['待纳入规划库项目统计'][2]
                    res['已启动前期工作数量统计'].map((item,index)=>{
                        if (item.ND != '' && item.ND != null){
                            year.push(item.ND)
                        }
                    })
                    const map = new Map()
                    this.roadYear= year.filter(key => !map.has(key) && map.set(key, 1))
                    console.log(that.roadYear)
                    this.gs =new Array(this.roadYear.length).fill(0);
                    this.gd =new Array(this.roadYear.length).fill(0);
                    this.sd = new Array(this.roadYear.length).fill(0);
                    this.gltz = new Array(this.roadYear.length).fill(0);
                    this.roadYear.map((item,index)=>{
                        var tz = 0
                        res['已启动前期工作数量统计'].map((subItem,subIndex)=>{
                            if (subItem.ND != '' && subItem.ND != null){
                                // if (subItem.ND == item){
                                //     tz += subItem.ZTZ
                                //     this.gltz.splice(index,1,parseFloat(tz.toFixed(2)))
                                // }
                                switch (subItem.QQGLLX) {
                                    case '高速公路':
                                        if(subItem.ND == item){
                                            this.gs.splice(index,1,subItem.Total)
                                        }
                                        break;
                                    case '普通国道':
                                        if(subItem.ND == item){
                                            this.gd.splice(index,1,subItem.Total)
                                        }
                                        break;
                                    case '普通省道':
                                        if(subItem.ND == item){
                                            this.sd.splice(index,1,subItem.Total)
                                        }
                                        break;
                                }
                            }
                        })
                    })
                    console.log(this.gs,this.gd,this.sd)

                    this.getEcharts1()
                    this.getEcharts2()

                    window.addEventListener("resize", () => {
                        this.myChart1.resize();
                        this.myChart2.resize();
                        // this.myChart3.resize();
                        // this.myChart4.resize();
                    });
                })
            },
            getPieData(res,name,data){
                data = []
                console.log(this.piegs)
                res.map((item)=>{
                    if(item.QQGLLX == name){
                        data.push(item)
                    }
                })
                var a = {GKWKZ:0,GK:0,CBSJ:0,SGT:0,Total:0,}
                data.map((item,indx)=>{
                    a.Total += item.Total
                    a.GKWKZ += item.GKWKZ
                    a.GK += item.GK
                    a.CBSJ += item.CBSJ
                    a.SGT += item.SGT
                })
                return a
            },
            getCurrent(){
                var that = this
                this.pieTotal = 0
                var searchData = {
                    wheres:JSON.stringify([{  Name:'Start', Value:this.searchYear.Start},{  Name:'End', Value:this.searchYear.End}]),
                };
                this.http.post('/api/Plan_high_national_early/EarlyStatisticsCurrent',searchData).then(res=>{
                    res.map((item)=>{
                        this.pieTotal +=( item.Total-item.GKWKZ)
                    })
                    console.log(this.pieTotal,'this.pieTotal')
                    this.piegs = this.getPieData(res,'高速公路',this.piegs)
                    this.piegd = this.getPieData(res,'普通国道',this.piegd)
                    this.piesd = this.getPieData(res,'普通省道',this.piesd)
                    console.log(this.piegs,this.piegd,this.piesd,'pieTotal')
                    this.getEcharts3()
                    this.getEcharts4()
                    window.addEventListener("resize", () => {
                        that.myChart3.resize();
                        that.myChart4.resize();
                    });
                })
            },
            getFxdata(){
                var searchData = {
                    wheres:JSON.stringify([{  Name:'Start', Value:this.searchYear.Start},{  Name:'End', Value:this.searchYear.End}]),
                };
                this.http.post('/api/Plan_high_national_early/EarlyStatisticsFX',searchData).then(res=>{
                    this.ydfxData = res['用地审批风险']
                    this.resultData =  this.ydfxData
                    this.zjccData = res['资金筹措风险']
                    this.dnrmx = res['待纳入规划库项目明细']
                    this.bsGetProductProcess()
                    // var dnrmxgs = []
                    // var dnrmxgd = []
                    // var dnrmxsd = []
                    // dnrmxgs.push(res['待纳入规划库项目明细'].find(item=>item.QQGLLX == '高速公路'))
                    // dnrmxgd.push(res['待纳入规划库项目明细'].find(item=>item.QQGLLX == '高速公路'))
                    // dnrmxsd.push(res['待纳入规划库项目明细'].find(item=>item.QQGLLX == '高速公路'))
                    // console.log(dnrmxgs)
                })
            },
            chooseYear(year){
                this.searchYear = year
                this.showXl = false
                this.getData()
                this.getCurrent()
                this.getFxdata()
            },
            showModal(name){
                this.dnrmxgd = []
                this.showM = true
                console.log(this.dnrmx.find(item=>{
                    if (item.QQGLLX == name){
                        this.dnrmxgd.push(item)
                    }
                }))
            },
            bsGetProductProcess() {
                clearInterval(this.tableTimer);
                this.tableTop = 0;
                this.tableActionFun();
                // if (this.activeFactoryId != "") {
                //     this.showFlag = false;
                //     this.$ajax({
                //         method: "get",
                //         url: `` //根据自己情况填接口url
                //     })
                //         .then(res => {
                //             this.resultData = res.data.data;
                //             this.showFlag = true;
                //
                //         })
                //         .catch(function(err) {
                //             console.log("bsGetProductProcess error!");
                //         });
                // }
            },
            changeFX(index){
                this.fxindex = index
                if (index == 0){
                    this.resultData = this.ydfxData
                }else{
                    this.resultData = this.zjccData
                }
                this.bsGetProductProcess()
            },
            tableActionFun() {
                this.resultDataSize = this.resultData.length;
                if (this.resultDataSize> this.visibleSize) {
                    this.resultData = this.resultData.concat(this.resultData);
                    this.tableTimerFun();
                } else {
                    this.fillTableList();
                }
            },
            //当数据过少时，不触发自动轮播事件，并填充没有数据的行，参数根据实际情况修改即可
            fillTableList() {
                var addLength = this.visibleSize - this.resultDataSize;
                for (var i = 0; i < addLength; i++) {
                    this.resultData.push({
                        planNo: "-",
                        type: "-",
                        startDate: "-",
                        endDate: "-",
                        process: "-"
                    });
                }
            },
            tableTimerFun() {
                var count = 0;
                this.tableTimer = setInterval(() => {
                    if (count < (this.resultData.length / 2) * this.lineHeight) {
                        this.tableTop -= 1;
                        count++;
                    } else {
                        count = 0;
                        this.tableTop = 0;
                    }
                }, this.tableTimerInterval);
            },
            changeMenu(index) {
                if(index != 0){
                    this.$message.warning('此功能暂未开放,敬请期待')
                    return false
                }
                this.active = index
            },
            //公路
            getEcharts1() {
                this.myChart1 = this.$echarts.init(
                    document.getElementById("echarts1")
                );
                var pieoption = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#fff'
                            }
                        }
                    },
                    toolbox: {
                        show:false
                    },
                    legend: {
                        data: ['高速', '普通国道', '普通省道'],
                        left:'right',
                        textStyle:{//图例文字的样式
                            color:'#9FDAFC',
                            fontSize:12
                        }
                    },
                    dataZoom: [
                        {
                            show: true,
                            realtime: true,
                            start: 0,
                            end: 100
                        },
                        // {
                        //     type: 'inside',
                        //     realtime: true,
                        //     start: 0,
                        //     end: 30
                        // }
                    ],
                    xAxis: [
                        {
                            type: 'category',
                            data:this.roadYear,
                            axisPointer: {
                                type: 'shadow'
                            },
                            axisLabel:{
                                color:'#9FDAFC',

                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            name: '数量(个)',
                            nameTextStyle:{
                                color:'#9FDAFC',
                            },
                            splitLine:{
                                show: true,
                                lineStyle:{
                                    color:['rgba(96, 118, 173, 0.3)'],
                                    type: 'solid'
                                }
                            },
                            axisLabel: {
                                formatter: '{value}',
                                textStyle:{//图例文字的样式
                                    color:'#9FDAFC',
                                    fontSize:14,
                                }
                            }
                        },
                    ],
                    series: [
                        {
                            name: '高速',
                            type: 'bar',
                            stack: 'road',
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + '公里';
                                }
                            },
                            label: {
                                show: true,
                                color:'#9FDAFC',
                                fontSize:'12px',
                                position: 'inside'
                            },
                            itemStyle:{
                                normal:{
                                    color:'#0070FB'
                                }
                            },
                            data:this.gs
                        },
                        {
                            name: '普通国道',
                            type: 'bar',
                            stack: 'road',
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + ' 公里';
                                }
                            },
                            label: {
                                show: true,
                                color:'#9FDAFC',
                                fontSize:'12px',
                                position: 'inside'
                            },
                            itemStyle:{
                                normal:{
                                    barBorderRadius:[0,0,0,0],
                                    color:'#FF5C5C'
                                }
                            },
                            data: this.gd
                        },
                        {
                            name: '普通省道',
                            type: 'bar',
                            stack: 'road',
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + ' 公里';
                                }
                            },
                            label: {
                                show: true,
                                color:'#9FDAFC',
                                fontSize:'12px',
                                position: 'inside'
                            },
                            data: this.sd,
                            barWidth : 30,
                            itemStyle:{
                                normal:{
                                    barBorderRadius:[2,2,0,0],
                                    color:'#1ED8F4'
                                }
                            },
                        },

                    ]
                };
                this.myChart1.setOption(pieoption);

            },
            getEcharts2() {
                this.myChart2 = this.$echarts.init(
                    document.getElementById("echarts2")
                );
                var pieoption = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#fff'
                            }
                        }
                    },
                    toolbox: {
                        show:false
                    },
                    legend: {
                        data: ['高速', '普通国道', '普通省道'],
                        left:'right',
                        textStyle:{//图例文字的样式
                            color:'#9FDAFC',
                            fontSize:12
                        }
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: ['2021', '2022', '2023','2024','2025'],
                            axisPointer: {
                                type: 'shadow'
                            },
                            axisLabel:{
                                color:'#9FDAFC',

                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            name: '数量(个)',
                            min: 0,
                            max: 100,
                            interval: 20,
                            nameTextStyle:{
                                color:'#9FDAFC',
                            },
                            splitLine:{
                                show: true,
                                lineStyle:{
                                    color:['rgba(96, 118, 173, 0.3)'],
                                    type: 'solid'
                                }
                            },
                            axisLabel: {
                                formatter: '{value}',
                                textStyle:{//图例文字的样式
                                    color:'#9FDAFC',
                                    fontSize:14,
                                }
                            }
                        },
                    ],
                    series: [
                        {
                            name: '高速',
                            type: 'bar',
                            stack: 'road',
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + '公里';
                                }
                            },
                            label: {
                                show: true,
                                color:'#9FDAFC',
                                fontSize:'12px',
                                position: 'inside'
                            },
                            itemStyle:{
                                normal:{
                                    color:'#0070FB'
                                }
                            },
                            data: [
                                23,31,22,42,12
                            ]
                        },
                        {
                            name: '普通国道',
                            type: 'bar',
                            stack: 'road',
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + ' 公里';
                                }
                            },
                            label: {
                                show: true,
                                color:'#9FDAFC',
                                fontSize:'12px',
                                position: 'inside'
                            },
                            itemStyle:{
                                normal:{
                                    barBorderRadius:[0,0,0,0],
                                    color:'#FF5C5C'
                                }
                            },
                            data: [
                                42,55,22,12,44
                            ]
                        },
                        {
                            name: '普通省道',
                            type: 'bar',
                            stack: 'road',
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + ' 公里';
                                }
                            },
                            barWidth : 30,
                            label: {
                                show: true,
                                color:'#9FDAFC',
                                fontSize:'12px',
                                position: 'inside'
                            },
                            data: [
                                23,31,22,42,12
                            ],
                            itemStyle:{
                                normal:{
                                    barBorderRadius:[2,2,0,0],
                                    color:'#1ED8F4'
                                }
                            },
                        },

                    ]
                };
                this.myChart2.setOption(pieoption);

            },
            getEcharts3() {
                var that = this
                this.myChart3 = this.$echarts.init(
                    document.getElementById("echarts3")
                );
                var pieoption = {
                    tooltip: {
                        trigger: 'item'
                    },
                    icon: 'squire', // 图例色块是方还是圆
                    title: {
                        text: that.pieTotal,
                        subtext: '总数量(个)',
                        textStyle: {
                            fontSize: 28,
                            color: "#ffffff",
                            fontWeight: 500
                        },
                        subtextStyle: {
                            fontSize: 16,
                            color: "#ffffff",
                            fontWeight: 400
                        },
                        textAlign: "center", //图例文字居中显示
                        x: "27%",   //距离左边的距离
                        y: "35%"    //距离上边的距离
                    },
                    color:['#0070FB','#FF5C5C','#1ED8F4'],
                    legend: {
                        selectedMode: false, //取消图例上的点击事件
                        orient:'vertical',
                        left:'52%',
                        top:'30%',
                        itemGap: 23,
                        textStyle: {
                            color: '#fff' , // 图例文字颜色
                            rich: {
                                a: {
                                    color: '#9FDAFC',
                                    padding:[0,10,0,0],
                                },
                                b: {
                                    color: '#FFF',
                                    width:40,
                                    align: 'left',
                                },
                                c: {
                                    padding:[0,0,0,10],
                                    color: '#37F0FC'
                                }
                            },

                        },
                        formatter:function(name){
                            let target,percentage;
                            var dataCake = [{name:'高速公路',value:(that.piegs.Total-that.piegs.GKWKZ)},{name:'普通国道',value:that.piegd.Total-that.piegd.GKWKZ},{name:'普通省道',value:that.piesd.Total-that.piesd.GKWKZ}]
                            console.log(dataCake,111111)
                            for(let i=0;i<dataCake.length;i++){
                                if(dataCake[i].name===name){
                                    target=dataCake[i].value
                                    percentage=dataCake[i].value/that.pieTotal*100
                                    percentage = percentage.toFixed(2)
                                }
                            }
                            let arr= [`{a|${name}}` + `{b|${target+'个'}}`+ `{c|${percentage+'%'}}`]
                            return arr.join(" ")

                        },
                    },
                    series: [
                        {
                            name: '已开展前期数量',
                            type: 'pie',
                            radius: ['55%', '70%'],
                            center: ['28%', '50%'],
                            avoidLabelOverlap: false,
                            label: {
                                show: false,
                                position: 'center',
                            },
                            emphasis: {
                                label: {
                                    show: false,
                                    fontSize: '30',
                                    fontWeight: 'bold',
                                }
                            },
                            labelLine: {
                                show: true
                            },
                            data: [
                                { value: that.piegs.Total-that.piegs.GKWKZ, name: '高速公路' },
                                { value: that.piegd.Total-that.piegd.GKWKZ, name: '普通国道' },
                                { value: that.piesd.Total-that.piesd.GKWKZ, name: '普通省道' },
                            ]
                        }
                    ]
                };
                this.myChart3.setOption(pieoption);

            },
            getEcharts4() {
                var that = this
                this.myChart4 = this.$echarts.init(
                    document.getElementById("echarts4")
                );
                var pieoption = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#fff'
                            }
                        }
                    },
                    toolbox: {
                        show:false
                    },
                    legend: {
                        data: ['工可', '初设', '施工图'],
                        left:'right',
                        textStyle:{//图例文字的样式
                            color:'#9FDAFC',
                            fontSize:12
                        }
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: ['高速', '普通国道', '普通省道','厅管重点项目'],
                            axisPointer: {
                                type: 'shadow'
                            },
                            axisLabel:{
                                color:'#9FDAFC',

                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            name: '',
                            nameTextStyle:{
                                color:'#9FDAFC',
                            },
                            splitLine:{
                                show: true,
                                lineStyle:{
                                    color:['rgba(96, 118, 173, 0.3)'],
                                    type: 'solid'
                                }
                            },
                            axisLabel: {
                                formatter: '{value}',
                                textStyle:{//图例文字的样式
                                    color:'#9FDAFC',
                                    fontSize:14,
                                }
                            }
                        }
                    ],
                    series: [
                        // {
                        //     name: '待工可评审',
                        //     type: 'bar',
                        //     tooltip: {
                        //         valueFormatter: function (value) {
                        //             return value + '个';
                        //         }
                        //     },
                        //     label: {
                        //         show: true,
                        //         color:'#9FDAFC',
                        //         fontSize:'12px',
                        //         position: 'top'
                        //     },
                        //     itemStyle:{
                        //         normal:{
                        //             barBorderRadius:[2,2,0,0],
                        //             color:'#249CF9'
                        //         }
                        //     },
                        //     data: [
                        //         that.piegs.GKWKZ,that.piegd.GKWKZ,that.piesd.GKWKZ
                        //     ]
                        // },
                        {
                            name: '工可',
                            type: 'bar',
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + '个';
                                }
                            },
                            label: {
                                show: true,
                                color:'#9FDAFC',
                                fontSize:'12px',
                                position: 'top'
                            },
                            itemStyle:{
                                normal:{
                                    barBorderRadius:[2,2,0,0],
                                    color:'#67E0E3'
                                }
                            },
                            data: [
                                that.piegs.GK,that.piegd.GK,that.piesd.GK
                            ]
                        },
                        {
                            name: '初设',
                            type: 'bar',
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + ' 个';
                                }
                            },
                            label: {
                                show: true,
                                color:'#9FDAFC',
                                fontSize:'12px',
                                position: 'top'
                            },
                            itemStyle:{
                                normal:{
                                    barBorderRadius:[2,2,0,0],
                                    color:'#EB6F49'
                                }
                            },
                            data:  [
                                that.piegs.CBSJ,that.piegd.CBSJ,that.piesd.CBSJ
                            ]
                        },
                        {
                            name: '施工图',
                            type: 'bar',
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + ' 个';
                                }
                            },
                            label: {
                                show: true,
                                color:'#9FDAFC',
                                fontSize:'12px',
                                position: 'top'
                            },
                            data: [
                                that.piegs.SGT,that.piegd.SGT,that.piesd.SGT
                            ],
                            itemStyle:{
                                normal:{
                                    barBorderRadius:[2,2,0,0],
                                    color:'#FDB628'
                                }
                            },
                        },
                    ]
                };
                this.myChart4.setOption(pieoption);

            },
        }

    }
</script>

<style scoped lang="less">
    .early{
        .nav{
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            .list{
                margin-right: 12px;
                cursor: pointer;
                line-height: 34px;
                width: 106px;
                height: 34px;
                font-size: 18px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #FFFFFF;
                background: url("../../assets/images/statistics/bg-menu.png");
                background-size: 106px 34px;
                text-align: center;
            }
            .list1{
                color: #00FFFF;
                background: url("../../assets/images/statistics/bg-menu-active.png");
                background-size: 106px 34px;
            }
        }
    }
    .bot{
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 24px;
        .left{
            width: 23vw;
            height:82vh;
            background: url("../../assets/images/statistics/bg-qqgl-l.png");
            background-size: 100% 100%;
            .title{
                margin: 20px auto;
                width: 20vw;
                height:46px;
                background: url("../../assets/images/statistics/bg-tit1.png");
                background-size: 100% 100%;
                font-size: 20px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
                text-align: center;
                line-height: 46px;
            }
            .title1{
                font-size: 16px;
                margin: 0 0 20px 23px;
                width: 15vw;
                height:30px;
                background: url("../../assets/images/statistics/tit4-bg.png");
                background-size: 100% 100%;
                line-height: 30px;
                text-align: left;
                padding-left: 20px;
            }
        }
        .middle{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            height: 82vh;
            .mt{
                padding: 22px 22px 0 22px;
                width: 42vw;
                height:51vh;
                background: url("../../assets/images/statistics/bg-qqgl-mt.png");
                background-size: 100% 100%;
                margin-bottom: 17px;
                .title{
                    width: 21vw;
                    height:45px;
                    background: url("../../assets/images/statistics/bg-tit1.png");
                    background-size: 100% 100%;
                    font-size: 20px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                    padding-left: 50px;
                    line-height: 45px;
                }
                .mtt{
                    display: flex;
                    align-items: center;

                    .line{
                        width: 1px;
                        height: 20vh;
                        background: #27E0FB;
                    }
                    .import{
                        margin-left: 8%;
                        width: 140px;
                        height: 20vh;
                        background: url("../../assets/images/statistics/bg-tzd.png");
                        background-size: 100% 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .ictzd{
                            margin-top: 20px;
                        }
                        .tzdNum{
                            font-size: 28px;
                            font-family: Source Han Sans CN;
                            color: #00FFFF;
                            span{
                                font-size: 16px;
                            }
                        }
                        .tzdname{
                            color: #9FDAFC;
                            margin-top: 12px;
                            font-size: 14px;
                        }
                    }
                }

            }
            .mb{
                width: 45vw;
                height:26vh;
                background: url("../../assets/images/statistics/bg-qqgl-mb.png");
                background-size: 100% 100%;
                .title{
                    margin: 22px 0  0 22px;
                    width: 21vw;
                    height:45px;
                    background: url("../../assets/images/statistics/bg-tit1.png");
                    background-size: 100% 100%;
                    font-size: 20px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                    padding-left: 50px;
                    line-height: 45px;
                }
                .dnr{
                    display: flex;
                    align-items: flex-start;
                    .tj{
                        margin-right: 12px;
                        width: 24px;
                        height: 23px;
                    }
                    .dnrr{
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        font-family: Source Han Sans CN;
                        .name{
                            font-size: 20px;
                            font-weight: 500;
                            color: #9FDAFC;
                        }
                        .num{
                            margin-top: 18px;
                            font-size: 28px;
                            font-weight: 500;
                            color: #00FFFF;
                            span{
                                color: #26B0FF;
                                font-size: 16px;
                            }
                        }
                        .btn{
                            cursor: pointer;
                            margin-top: 18px;
                            width: 77px;
                            height: 24px;
                            background: #2A70E9;
                            border-radius: 12px;
                            color: #ffffff;
                            text-align: center;
                            line-height: 24px;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        .right{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            height: 82vh;
            width: 22vw;
            .rt{
                width: 23vw;
                height:32vh;
                background: url("../../assets/images/statistics/bg-qqgl-mt.png");
                background-size: 100% 100%;
                margin-bottom: 17px;
                .title{
                    margin: 20px auto;
                    width: 17vw;
                    height:46px;
                    background: url("../../assets/images/statistics/bg-tit1.png");
                    background-size: 100% 100%;
                    font-size: 20px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                    text-align: left;
                    line-height: 46px;
                    padding-left: 50px;
                }
                .rtb{
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly   ;
                }

                .rtbo{
                    width: 121px;
                    height:171px;
                    background: url("../../assets/images/statistics/bg-dtx.png");
                    background-size: 100% 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    .rtboi{
                        width: 69px;
                        height: 65px;
                    }
                    .rtbot{
                        font-size: 14px;
                        color: #00BEDB;
                    }
                    .rtbon{
                        text-shadow:1px 1px 20px #00deff;
                        font-family:AliHYAiHei;
                        font-size: 30px;
                        font-weight: 500;
                        color: #FFFFFF;
                    }
                }
                .rtbo1{
                    background: url("../../assets/images/statistics/bg-ddb.png");
                    .rtbot{
                        font-size: 14px;
                        color: #FFC000;
                    }
                    .rtbon{
                        text-shadow:1px 1px 20px #ffc000;
                    }
                }
                .rtbo2{
                    background: url("../../assets/images/statistics/bg-dyt.png");
                    .rtbot{
                        font-size: 14px;
                        color: #DB0000;
                    }
                    .rtbon{
                        text-shadow:1px 1px 20px #ff0000;
                    }
                }
            }
            .rb{
                width: 23vw;
                height:47vh;
                background: url("../../assets/images/statistics/bg-qqgl-rb.png");
                background-size: 100% 100%;
                .title{
                    margin: 20px auto;
                    width: 17vw;
                    height:46px;
                    background: url("../../assets/images/statistics/bg-tit1.png");
                    background-size: 100% 100%;
                    font-size: 20px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                    text-align: left;
                    line-height: 46px;
                    padding-left: 50px;
                }
                .rbo{
                    font-family: Source Han Sans SC;
                    width: 180px;
                    height: 75px;
                    background: url("../../assets/images/statistics/bg-i1.png") no-repeat;
                    background-size: 100% 100%;
                    .rboi{
                        margin: 0 20px;
                    }
                    .rbot{
                        font-size: 14px;
                        font-weight: 500;
                        color: #00FFFF;
                    }
                    .rbon{
                        font-size: 18px;
                        font-weight: 400;
                        color: #00FFFF;
                        span{
                            font-size: 14px;
                        }
                    }
                }
                .rbo1{
                    width: 180px;
                    height: 70px;
                    background: url("../../assets/images/statistics/bg-i2.png") no-repeat;
                    background-size: 100% 100%;
                    .rbot{
                        font-size: 14px;
                        font-weight: 500;
                        color: #5D97F3;
                    }
                    .rbon{
                        font-size: 18px;
                        font-weight: 400;
                        color: #5D97F3;
                    }
                }
                .tb{
                    .tablel{
                        margin: 11px auto 0;
                        display: flex;
                        width:21vw;
                        background: url("../../assets/images/statistics/bg-table-h.png");
                        background-size: 100% 100%;
                        height: 40px;
                        font-size: 13px;
                        font-family: Source Han Sans SC;
                        font-weight: 400;
                        color: #C9E5FF;
                        line-height: 40px;
                        text-align: center;
                        .tn{
                            margin-left: 10px;
                            text-align: left;
                            width: 10vw;
                        }
                        .dj{
                            width: 20vw;
                        }
                        /*.gm{*/
                        /*    width: 5vw;*/
                        /*}*/
                    }
                    .tc{
                        background: rgba(0, 150, 255, .1);
                        margin: 11px auto 0;
                        display: flex;
                        width:21vw;
                        height: 30px;
                        font-size: 13px;
                        font-family: Source Han Sans SC;
                        font-weight: 400;
                        color: #C9E5FF;
                        line-height: 30px;
                        text-align: center;
                        .tn{
                            margin-left: 10px;
                            text-align: left;
                            width: 10vw;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        .dj{
                            width: 20vw;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        .gm{
                            width: 5vw;
                        }
                    }
                }
            }
        }
        .echarts{
            width: auto;
            height: 35vh;
        }
        .echarts1{
            width: 65%;
            height: 26vh;
        }
        .echarts2{
            width: auto;
            height: 24vh;
        }
    }
    .flex{
        display: flex;
        align-items: center;
    }
    .modal{
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        .meng{
            top: 0;
            left: 0;
            position: absolute;
            background: rgba(0,0,0,.5);
            width: 100vw;
            height: 100vh;
            z-index: 2;
        }
        .mk{
            z-index: 1000;
            box-shadow: 0px 0 30px #2A70E9 ;
            margin: 10% auto 0;
            width: 564px;
            height: 567px;
            background: #050B28;
            border: 1px solid #2A70E9;
            border-radius: 4px;
            .cw{
                border-radius: 4px;
                border: 0px solid #050B28;
                box-shadow:inset 0px 0 30px #2A70E9 ;
                width: 564px;
                height: 567px;
                position: relative;
                .close{
                    cursor: pointer;
                    width: 64px;
                    height: 64px;
                    position: absolute;
                    right: -32px;
                    top: -32px;
                }
            }
            .mt{
                text-align: center;
                margin: 0 auto;
                width: 304px;
                height: 42px;
                line-height: 42px;
                background: #2A70E9;
                border-radius: 0px 0px 20px 20px;
                font-size: 20px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
            }
            .mc{
                margin: 20px auto;
                overflow-y: scroll;
                width: 488px;
                height: 480px;
                div{
                    color: #FFFFFF;
                    font-size: 16px;
                    font-family: Source Han Sans SC;
                    height: 50px;
                    line-height: 50px;
                    border-bottom: 1px solid rgba(0, 112, 251, .3);
                }
            }
        }
    }
    ::-webkit-scrollbar {
        /*隐藏滚轮*/
        display: none;
    }
    .xl{
        width: 90px;
        height: 28px;
        background: rgba(31,158,204,0.2000);
        border: 1px solid #2099C5;
        border-radius: 4px;
        margin-left: 14px;
        text-align: center;
        line-height: 28px;
        color: #fff;
    }
    .xlcd{
        cursor: pointer;
        z-index: 10;
        border: 1px solid #0955B3;
        background: rgba(31,158,204,0.2);
        left: 14px;
        position: absolute;
        div{
            width: 90px;
            height: 28px;
            text-align: center;
            line-height: 28px;
            color: #fff;
            border-top: 1px solid #fff;
        }
    }
</style>
